.Background{
    height: 65vh;
    width: 100%;
    object-fit: cover;
    filter: grayscale(20%);
}



.Centered{
    position: absolute;
    font-size: 75px;
    width: 100%;
    text-align: center;
    top: 20%;
    color: white;
    font-weight: bold;
}
.ContactButton{
    position: absolute;
    font-size: 20px;
    top: 30%;
    left: 30%;
    transform: translate(-340%,280%);
    color: white;
    float: right;
    background-color: #61dafb;
    display: inline-block;
    text-align: center;
    border-radius: 12px;
    padding: 5px 30px 5px 30px;
}
.AboutButton{
    position: absolute;
    font-size: 20px;
    top: 30%;
    left: 30%;
    transform: translate(-220%,280%);
    color: white;
    float: right;
    background-color: #61dafb;
    display: inline-block;
    text-align: center;
    border-radius: 12px;
    padding: 5px 35px 5px 35px;
}
