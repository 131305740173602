.NavigationBar{
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    list-style-type: none;
    overflow: hidden;
    position: relative;
}

.Logo{
    margin-left: 16vw;
    float: left;
    color: black;
    font-size: 20px;
    font-weight: bold;
}
li a {
    float: right;
    display: block;
    text-align: center;
    color: inherit;
    padding: 0.2vw 1.25vw;
    text-decoration: none;
}
a:active{
    color:blue;
}
a:hover{
    color:blue;
}

.Button{
    margin-right: 12vw;
    margin-left: 8vw;
    float: right;
    color: white !important;
    background-color: #61dafb;
    display: inline-block;
    border: none;
    text-align: center;
    border-radius: 12px;
}